<template>
    <validation-observer ref="form" v-slot="{ invalid }" tag="div">
        <b-overlay :show="status === 'LOADING'">
            <b-form @submit.prevent="submit">
                <b-row>
                    <b-col md="6">
                        <custom-input
                            v-model="user.name"
                            :name="$t('name')"
                            required
                            rules="required|min:2"
                            vid="name"
                        />
                    </b-col>
                    <b-col md="6">
                        <custom-input
                            v-model="user.email"
                            :name="$t('Email')"
                            readonly
                            vid="email"
                        />
                    </b-col>
                    <b-col v-if="user.role && $can('acl-view')" md="6">
                        <custom-select
                            v-model="user.role.id"
                            :options="selectableRoles"
                            :name="$t('role')"
                            vid="role"
                        />
                    </b-col>
                </b-row>
                <b-row v-if="$can('acl-view')">
                    <b-col cols="12">
                        <div class="p-1 rounded border">
                            <h4 class="mb-2">
                                <feather-icon class="font-large-1" icon="UserIcon"/>
                                مجوز ها
                            </h4>
                            <div>
                                <b-row>
                                    <b-col
                                        v-for="permission in permissionsList"
                                        :key="permission.id"
                                        class="mb-1"
                                        cols="6"
                                        md="4"
                                    >
                                        <b-form-checkbox
                                            v-model="user.permissions"
                                            :value="permission.id"
                                            name="per"
                                            :disabled="! $can('acl-edit')"
                                        >
                                            {{ permission.name }}
                                        </b-form-checkbox>

                                    </b-col>
                                </b-row>

                            </div>
                        </div>
                    </b-col>
                </b-row>
                <b-alert
                    :show="errMessage !== null"
                    class="p-2"
                    dismissible
                    variant="danger"
                >
                    {{ errMessage }}
                </b-alert>

                <b-button
                    v-if="$can('user-edit')"
                    :disabled="invalid"
                    class="mt-2"
                    type="submit"
                    variant="primary"
                >
                    {{ $t('save') }}
                </b-button>

            </b-form>
        </b-overlay>
    </validation-observer>
</template>

<script>
import { mapGetters } from "vuex";
import { BAlert, BFormCheckbox } from "bootstrap-vue";

export default {
    name: "Form",

    components: {
        BAlert,
        BFormCheckbox,
    },

    props: {
        submit: {
            type: Function,
            required: true,
        },
        user: {
            default() {
                return {
                    name: '',
                    role: '',
                    permissions: []
                }
            },
            type: Object
        }
    },

    data() {
        return {
            errMessage: null,
        }
    },

    computed: {
        ...mapGetters('user', [
            'status', 'permissionsList','selectableRoles'
        ])
    },

}
</script>