<template>
    <b-card>
        <Form ref="createUser" :submit="submit" :user="user"/>
    </b-card>
</template>

<script>
import Form from "./partials/Form";
import { mapActions, mapGetters } from "vuex";

export default {
    name: "EditUser",

    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.findUser(vm.id)
            // if (this.$can('acl-view') || this.$can('acl-edit')) {
                vm.permissionsList()
                vm.rolesList()
            // }
        })
    },

    components: {
        Form
    },

    data() {
        return {
            id: this.$route.params.id
        }
    },

    computed: {
        ...mapGetters('user', [
            'user'
        ])
    },

    methods: {
        ...mapActions({
            editUser: 'user/edit',
            findUser: 'user/find',
            permissionsList: 'user/fetchPermissionsList',
            rolesList: 'user/fetchRolesList',
        }),
        submit() {
            const refUser = this.$refs.createUser
            refUser.errMessage = null

            const data = JSON.parse(JSON.stringify(refUser.user))
            this.editUser(data).then(() => {
                // this.$router.push({ name: 'users' })
                this.$swal({
                    icon: 'success',
                    title: this.$t(`Edited!`),
                    text: this.$t('Your file has been edited.'),
                    confirmButtonText: this.$t('Ok!'),
                    customClass: {
                        confirmButton: 'btn btn-success',
                    },
                })
            }).catch(response => {
                if (response.errors) {
                    refUser.$refs.form.setErrors(response.errors)
                }
                refUser.errMessage = response.message
            })
        }
    }
}
</script>
